import React, { Component } from "react";
import './App.css';
import MainWindow from "./MainWindow";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


class App extends Component {
  constructor () {
    super();
	  this.state = {
    }

  } 
 
 render ()
  {
    return (
      <Router>
        <Routes>
          <Route exact path="/behandling" element={<MainWindow view='behandling'/>}>
          </Route>
          <Route exact path="/sliping" element={<MainWindow view='sliping'/>}>
          </Route>
          <Route exact path="/kontakt" element={<MainWindow view='kontakt'/>}>
          </Route>
          <Route exact path="/" element={<MainWindow/>}>
          </Route>
        </Routes>
    </Router>

    );
  }
}

export default App;
