import React, { Component } from "react";
import './App.css';
import ContactForm from './ContactForm';
import ring from './ring.svg';
import envelope from './envelope.svg';

import { getClientData } from "./DataModelEngine";

class ContactBaloons extends Component {
    constructor () {
        super();
        this.state = {
        }

    }


  render (){      
    return (
    <div className="contact-us">
        <div className="content-background" style={{backgroundPosition: '50% 100%'}}></div>
        <div className="contact-us-description">
        <p style={{fontVariant: 'none', fontSize: '2rem', padding: '0% 0',fontWeight: '600'}}>
          <span>Gratis Befaring</span> <br/>
          Bestill besøk av vår gulvslipespesialist.
        </p>
        </div>
            
            <a className="contact-data" name='ring' onClick={this.footprint} href={'tel:'+getClientData('clientPhone')}>
              <div id="contact-data">
                <div style={
                  {
                      backgroundImage: 'url('+ring+')',
                      backgroundRepeat: 'no-repeat',
                      width: "40%",
                      height: "40%",
                      position: "absolute",
                      filter: 'invert(1)',
                      top: '45%',
                      left: '40%',
                      transform: 'rotateZ(-15deg)'
                  }
                }/>
                <h1>
                  {getClientData('clientFancyPhone')}
                </h1>
              </div>
            </a>
            <a className="contact-data" name='email' onClick={this.footprint} href={"mailto:"+getClientData('clientEmail')}>
                    <div id="contact-data" style={{opacity: 0.9, backgroundColor: '#00001F'}}>
                    <div style={
                                {
                                    backgroundImage: 'url('+envelope+')',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100%',
                                    width: "40%",
                                    height: "40%",
                                    filter: 'invert(1)',
                                    position: "absolute",
                                    top: '45%',
                                    left: '33%',
                                }
                            }></div>
                        
                        <h5>
                        <br/>
                        {getClientData('clientEmail')}
                        </h5>
                    </div></a>

        </div>
    )}
}

export default ContactBaloons;