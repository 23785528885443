import React, { Component } from 'react';
import { getClientData, getYear } from './DataModelEngine';
import logo from './logo.svg';

class Footer extends Component {
	render() {
		return (
      <>

		<div className='footer'>
				<div className='footer-logo'>
	
					<img src={logo} alt={getClientData('clientCompanyName')}/>
          <p>
          {getClientData('clientCompanyName')}
        </p>

        <p className='footer-address'>
          {getClientData('clientAddress')}
        </p>

				</div>
			<div>		
				<p>{'Copyright ' + getYear() + ' ' + getClientData('clientCompanyName')}
        </p>

			</div>
			<div className='footer-contact'>		
			<p>
                <a name='mail' onClick={this.footprint} href={"mailto:"+getClientData('clientEmail')}>{getClientData('clientEmail')}</a> 
              </p>
              <p>
                <a name='ring' onClick={this.footprint} href={'tel:'+getClientData('clientPhone')}>{getClientData('clientFancyPhone')}</a>
              </p>			
			</div>


		{/*<footer >
          <Row >
            <Col xs={{span: 12, order: 12}} lg={{span: 4, order: 1}} >
              <p>
                <a name='mail' onClick={this.footprint} href={"mailto:"+getClientData('clientEmail')}>{getClientData('clientEmail')}</a> 
              </p>
              <p>
                <a name='ring' onClick={this.footprint} href={'tel:'+getClientData('clientPhone')}>{getClientData('clientFancyPhone')}</a>
              </p>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 4, order: 4}}>
            <img src={logotype} className='logotype-footer' alt={getClientData('clientCompanyName')}/>
              <p style={{width: "100%", height: '25px', textAlign: 'center'}}>Copyright 2023</p>
            </Col>
            <Col xs={{span: 12, order: 6}} lg={{span: 4, order: 12}}>
              <p>
                {getClientData('clientCompanyName')}
              </p>
              <p>
                Antenneveien 1
              </p>
              <p>
                1154 Oslo
              </p>
             
            </Col>
          </Row>
        </footer>
        <Row id='webdesign'>
          <Col>
          <div style={{width: "100%", height: '70px', textAlign: 'center'}}>            
            <p style={{width: "100%", height: '25px', textAlign: 'center'}}><a name='laskowski' onClick={this.footprint} href='https://laskowski.no'>Webdesign Laskowski Creative</a></p>
          </div>
          </Col>
        </Row>*/
		}
		</div>
    <div id='webdesign'>
      <div></div>
          <div>
          <div style={{width: "100%", height: '50px', textAlign: 'center'}}>            
            <p style={{width: "100%", height: '25px', textAlign: 'center'}}><a name='laskowski' onClick={this.footprint} href='https://laskowski.no'>Webdesign Laskowski Creative</a></p>
          </div>
          </div>
          <div></div>
        </div>
    
      </>
		) 
	}
}

export default Footer;