
import React, { Component } from 'react';
import PicturesGallery from './PicturesGallery';

class AboutUs extends Component {
	render() {
		const folderName = ['about'];
		const title = ['Galleri']
		const photos = [
			[
				{file: 'jordans_parkett_01.jpg', fill: 150, x: 50, y: 70},
				{file: 'jordans_parkett_02.jpg', fill: 177, x: 50, y: 50},
				{file: 'jordans_parkett_06.jpg', fill: 150, x: 50, y: 70},
				{file: 'jordans_parkett_07.jpg', fill: 177, x: 50, y: 50},
				{file: 'jordans_parkett_08.jpg', fill: 177, x: 50, y: 50},
				{file: 'jordans_parkett_09.jpg', fill: 177, x: 60, y: 50},
                {file: 'jordans_parkett_10.jpg', fill: 177, x: 80, y: 50},
				{file: 'jordans_parkett_11.jpg', fill: 177, x: 80, y: 50},
				{file: 'jordans_parkett_03.jpg', fill: 177, x: 50, y: 50},
				{file: 'jordans_parkett_04.jpg', fill: 177, x: 60, y: 50},
				{file: 'jordans_parkett_12.jpg', fill: 135, x: 80, y: 50},
				{file: 'jordans_parkett_13.jpg', fill: 135, x: 80, y: 50},
				{file: 'jordans_parkett_14.jpg', fill: 135, x: 80, y: 50},
				{file: 'jordans_parkett_15.jpg', fill: 135, x: 80, y: 50},
				{file: 'jordans_parkett_16.jpg', fill: 135, x: 8, y: 50},
				{file: 'jordans_parkett_17.jpg', fill: 135, x: 8, y: 50},
			],

		];
		const description = [
			''
		]
		
		return (
		<div id="about" className='gallery'>
			<div>
				{folderName.map((gallery, index)=>(
					<PicturesGallery description={description} folderName={gallery} title={title[index]} photos={photos[index]}/>
				))}
			</div>
			
		</div>
		) 
	}
}

export default AboutUs;