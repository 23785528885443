import React, { Component } from "react";
import { Row, Col, Form, FormLabel, Container} from 'react-bootstrap';

class RangeComponent extends Component {
  
  handleChange = (e) => {
    let {value, name } = e.target,
    returnValue = this.props.saveValue;
    value = +value;
    returnValue(name, value);
  }

  render (){
    let {index, value, record} = {...this.props};
    return (
      <Container fluid>
        <Row>
          <Col lg={6} xs={12}>
          <Form.Label className="text-center d-lg-block"><h4>{'GULVAREAL'}</h4></Form.Label>
          </Col>       
          <Col lg={6} xs={12}>
            <Form.Control
            name={record} 
            type="range" 
            min={1}
            max={300}
            value={value} 
            placeholder={record} 
            onChange={this.handleChange} 
            required/>
             {+value < 300 && <Form.Label className="text-right d-lg-block"><h4>{value + ' ' +'m'}<span className="sub">{'2'}</span></h4></Form.Label>}
             {+value === 300 && <Form.Label className="text-right d-lg-block"><h4>{value + ' ' +'m'}<span className="sub">{'2'}</span>{' og mer'}</h4></Form.Label>}
          </Col>
        </Row>
    </Container>
    );
  }
}

export default RangeComponent;
