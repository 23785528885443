import React, { Component } from "react";
import './App.css';
import ContactForm from './ContactForm';
import ring from './ring.svg';
import envelope from './envelope.svg';

import { getClientData } from "./DataModelEngine";
import Tilbud from "./Tilbud";

class OrderBefaring extends Component {
    constructor () {
        super();
        this.state = {
        }

    }


  render (){      
    return (
    <div className="order-befaring">
        <div className="content-background" style={{backgroundPosition: '50% 100%'}}></div>
        <div className="contact-us-description">
        <p style={{fontVariant: 'none', fontSize: '2rem', padding: '0% 0',fontWeight: '600'}}>
                    <span>Gratis Befaring</span> <br/>
                    Bestill besøk av vår gulvslipespesialist.
                </p>
        </div>
          <Tilbud/>
        </div>
    )}
}

export default OrderBefaring;