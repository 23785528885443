import React, { Component } from "react";
import Cookies from 'universal-cookie';
import NavigationBar from './NavigationBar';
import MainContent from './MainContent';
import Footer from './Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import $ from 'jquery';
import CallPopup from "./CallPopup";
import CookiesPopup from "./CookiesPopup";

class MainWindow extends Component {
  constructor () {
	  super();
	  this.state = {
      selected: 'hjem',
      siteContent: [
        {
          link: 'RenovationPage',
          category: 'hjem',
          pictures: {mini: '16.jpg', slider: '16.jpg'},
          title: 'Sliping av tregulv i Oslo',
          description: [
            'Jordan\'s Parkett er et firma som utfører støvfri gulvsliping i Oslo og omegn.',
            'Vi jobber med alle typer tregulv og tilbyr et bredt spekter av overflatebehandlinger. Uansett om du trenger hjelp til sliping av gulv, trapper eller overflatebehandling, har vi kompetansen og erfaringen til å hjelpe deg.',
            'Gulvslipearbeider i hele Viken fylke. Vi holder til i Oslo, men jobber også i Drammen, Asker, Bærum, Akershus, Buskerud, Lier, Vestby, Frog, Lørenskog og resten av Viken.',
          ],
          caption: 'Renovering av parkett og tretrapper.',
        },
        {
          link: 'RenovationPage',
          category: 'hjem',
          pictures: {mini: 'hjem.jpg', slider: '15_slider.jpg'},
          title: 'Gulvsliptjenester i Oslo',
          description: [
            'Jordan’s Parkett slipetjeneste er designet for å maksimere kundetilfredsheten og forhindre misforståelser.',
            'Dette betyr at:',
            '- Vi tilbyr alt fra parkettsliping til sliping av trapper.',
            '- Fungerer med moderne slipeteknologi som sliper gulvet til perfeksjon hver gang.',
            '- Jobber for å holde tiden på hvert oppdrag.',
            '- Vi tar ansvar når det er på tide å ta ansvar.'
            ],
          caption: 'Renovering av parkett og tretrapper.',
        },
				{
					link: 'LayingPage', //temporary unused
          category: 'legging',
					pictures: {mini: '6.jpg', slider: '6_slider.jpg'},
					title: 'Gulvlegging',
					description: ['Unøyaktighet liker å ta hevn og har ingen plass i arbeidet vårt. Vi vet hvor viktig det er å presist kutte parketten, koble sammen tregulvelementer med keramiske overflater og installere gulvlister. Det hele har stor innvirkning på det endelige utseendet og holdbarheten til det installerte gulvet.',],
          caption: 'Vi ordner tregulv og installerer laminatgulv i rom for ulike formål: fra stille soverom, gjennom kafeer, til idrettshaller.',
				},
        {
        link: 'DustFree',
        category: 'sliping',
        pictures: {mini: '2.jpeg', slider: '2_slider.jpg'},
        title: 'Støvfri Gulvsliping',
        description: [
          'Vi er sertifisert for å utføre støvfri sliping. En vanlig slipemaskin pisker opp farlig byggestøv i hele leiligheten.',
          'Når du velger å slipe gulvet hos oss velger du en trygg og støvfri opplevelse. Teknologien vi bruker reduserer støv med opptil 99,9%.',
          'Støvfri sliping krever ikke mer tid, og er heller ikke dyrere enn tradisjonell sliping. Det er også mye mindre risiko for brann og helserisiko for oss som sliper gulvet. Så det er en vinn-vinn for både deg som kunde og oss som kvern.',
          
        ],
        caption: 'Omfattende parkettsliping. ',
      },
				{
					link: 'GridingPage',
          category: 'sliping',
          pictures: {mini: '6.jpg', slider: '6_slider.jpg'},
					title: 'Sliping av parkettgulv',
					description: [
            'Et av de vanligste oppdragene vi utfører er sliping av parkettgulv. Det er en veldig takknemlig gulvoverflate å pusse og blir vanligvis restaurert til så nær ny tilstand du kan komme.',
            'Å slipe et tregulv er også et klimavennlig alternativ fordi de utnytter ressursene som allerede finnes i stedet for å legge et helt nytt gulv.',
            'Du sparer også penger på å slipe gulvet sammenlignet med å legge et helt nytt gulv. Er du usikker på om gulvet ditt kan reddes med omsliping eller ikke? De fleste tregulv kan lagres, men legg gjerne ved et bilde på e-post eller SMS så kan vi raskt finne ut om det kan lagres med ny sliping eller ikke.',
          ],
          caption: 'Omfattende parkettsliping. ',
				},
				{
					link: 'OmSliping',
          category: 'sliping',
					title: 'Slipe Furugulv',
          pictures: {mini: '9.jpg', slider: '9_slider.jpg'},
					description: [
            'Gulv av gran og furu er vanlig. Et vakkert gulv som både er slitesterkt og krever lite vedlikehold.',
            'Dessverre slites gulvet med årene og må derfor pusses på nytt med jevne mellomrom. Vi har god forståelse for å jobbe med denne typen gulv og svarer mer enn gjerne på dine spørsmål om dette.',
            'Mange av våre kunder mener at gulvet eller trappene deres er umulige å spare. Vi har en lang historie med å redde de mest slitte gulvene.',
          ],
          caption: 'Omfattende gulvsliping. ',
				},    
        {
					link: 'HvorforASlipeGulv',
          category: 'extra',
					title: 'Hvorfor velge gulvsliping?',
          pictures: {mini: '16.jpg', slider: '4_slider.jpg'},
					description: [
            'Kunder bestemmer seg for å slipe gulvene sine når de vil:',
            'fjerne misfarging og synlige skader på parkettgulvet,',
            'bli kvitt smuss som er vanskelig å fjerne,',
            'fjerne gamle lag av lakk,',
            'jevn, glatt gulvoverflaten,',
            'endre fargen på eksisterende parkett.'

          ],
          caption: 'Omfattende gulvsliping. ',
				},      
				{
					link: 'BehandlingPage',
          category: 'behandling',
					pictures: {mini: '16.jpg', slider: '1_slider.jpg'},
					title: 'Gulvbehandling',
					description: [
            'Etter sliping behandler vi gulvet med ulike produkter for å beskytte mot smuss og søl. Det kan inkludere klar polyuretanlakk, olje eller hardvoksolje.',
            'Lakk er slitesterk og krever minimalt med vedlikehold, mens hardvoksolje bør smøres på nytt med vedlikeholdsolje for å opprettholde beskyttelsen over tid.',
            'Tørketiden varierer avhengig av valgt overflatebehandling. Etterbehandling kan omfatte tre strøk med polyuretanlakk på 2-3 timer eller to strøk med hardvoksolje som trenger 8-12 timer hver. ',
            'Gulvet kan brukes neste dag, men tepper bør unngås inntil en uke etter behandling for å tillate fullstendig herding.'
        ],
          caption: 'Gulvlakkertjenester.',
				},
				{
					link: 'VarnishingPage',
          category: 'behandling',
					pictures: {mini: '1.jpeg', slider: '1_slider.jpg'},
					title: 'Gulvbehandling med lakk',
					description: ['Lakkering er det høyeste profesjonalitetsnivået i yrket, derfor er det ikke alle selskaper som påtar seg denne oppgaven.',
          'Den riktige fargen på gulvet vil gjøre det til en integrert del av interiøret, og påvirke dets harmoni',
          'Over 30 års erfaring gjør at vi kan møte dine forventninger når det gjelder ønsket gulvfarge. Vårt firma har hatt mange vellykkede implementeringer. Lakkering lar deg matche gulvet til ditt drømmeinteriør. Det gir et uendelig antall kombinasjoner for hver kunde.'],
          caption: 'Gulvlakkertjenester.',
				},
        {
          link: 'WaxPage',
          category: 'behandling',
          pictures: {mini: 'hardwax.jpg', slider: '11_slider.jpg'},
          title: 'Behandling med oljevoks',
          description: ['Oljevoks i forhold til vanlig olje har et ekstra belegg. Som olje er det helt økologisk, slitesterkt og gir gulvet et naturlig utseende.',
          'Ved lakkerte gulv krever skader sliping. Oljevoks gir mulighet for lokale reparasjoner, noe som reduserer driftskostnadene.',
          'Oljen trenger dypt inn i brettet og det ytre lag av voks beskytter gulvet mot riper, flom og vanlig, intensivert daglig bruk.'],
          caption: 'Oljevoksingstjenester på gulv.',
        },
				{
					link: 'OilingPage',
          category: 'behandling',
					pictures: {mini: 'oiling.png', slider: '13_slider.jpg'},
					title: 'Oljebehandlinger',
					description: [
            'Oljen understreker treets struktur og gir det et naturlig utseende.',
            'Et oljet gulv beskrives ofte som et stuegulv med varme. I motsetning til lakkerte gulv er oljede gulv mye mer uttrykksfulle og lettere å renovere.',
            'Hvis gulvet får riper eller trykkskader, er det lettere å reparere overflaten. Man kan også raskt gi overflaten et løft ved å polere og påføre ny olje uten å slipe overflaten på nytt.',
          ],
          caption: 'Gulvoljingstjenester.',
				},
      ],
    }
  }

  handshake() {
    const inquiry = {uid: 'JP777'};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/handshake', 
        global: 'https://laskowskicreative.no/api/lc/handshake'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiry)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
      {
        activatePage: json.handshake
      }
      ))  
    }

 
    selectMenu = (menu) => {
      this.setState({
        selected: menu,
      })
    }


  componentDidMount() {
        const view = this.props.view ? this.props.view : false;
        if (view)
        {this.selectMenu(view)};
        this.handshake();
        
      
    //this.handshake();
    $(document).scroll(function () {
      var y = $(this).scrollTop();
      y = y+400;
      $('.description').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });
      $('.miniature').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });
      $('.picture').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).slideDown();
        } else {
            $(this).slideUp();
        }
      });
      
    });
  }

  render (){
    const cookies = new Cookies();
    const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
    return (
      <div className="App" style={{display: this.state.activatePage === false ? 'none' : 'grid'}}>
        <nav>
          <NavigationBar selectMenu={this.selectMenu} siteContent={this.state.siteContent}/>
        </nav>
          <MainContent selected={this.state.selected} siteContent={this.state.siteContent}/>
          <CallPopup/>
          {!cookiesAccepted && <CookiesPopup/>}
        <footer>
          <Footer/>
        </footer>
      </div>
    );
  }
}

export default MainWindow;
