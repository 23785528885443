import React, { Component } from "react";
import './App.css';
import { Navbar, Nav} from 'react-bootstrap';
import logo from './logo.svg';

class NavigationBar extends Component {
    constructor () {
        super();
        this.state = {
        }
        this.reportUsage = this.reportUsage.bind(this);
    }
    
  reportUsage(event) {
    let view = event.target.name;
    const inquiry = {uid: 'JP777', view};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/usagereport', 
        global: 'https://laskowskicreative.no/api/lc/usagereport'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiry)
    })
    
    }

    

  render (){
    const basicMenu = [{title: "Hjem", link: '/'}, {title: "Gulvsliping", link: 'sliping'}, {title: "Gulvbehandling", link: 'behandling'}, {title: "Kontakt oss", link: 'kontakt'}];

    return (
        <Navbar expand="lg" collapseOnSelect>
            <Navbar.Brand href="/"><img src={logo} alt="Jordan's Parkett AS"/>Jordan's Parkett AS</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav className="ml-auto">
                    {basicMenu.length > 0 && basicMenu.map((item, index)=>(
                        <Nav.Link name={item.link} key={index} href={item.link} onClick={this.reportUsage}>{item.title}</Nav.Link>                    
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )}
}

export default NavigationBar;