import React, { Component } from "react";
import './App.css';
import AboutUs from './AboutUs';
import ContactPanel from './ContactPanel';
import Preface from './Preface';
import logo from './logo.svg';
import Tilbud from "./Tilbud";
import ContactBaloons from "./ContactBaloons";
import GoogleComments from "./googleComments/GoogleComments";
import OrderBefaring from "./OrderBefaring";

class MainContent extends Component {

  render (){      
    const {siteContent, selected} = {...this.props};
    return (
    <main>
        {siteContent.length > 0 && siteContent.map((item, index)=>( 
        <>   
            <div key={index} id={item.link} className='content' style={{display: item.category !== selected ? 'none' : 'grid'}}>
                <div className="content-background"></div>
                <div className='picture'>
                    <div className="picture-logo" style={{backgroundImage: 'url("./logo_square.svg")'}}/>          
                    <div className="picture-img" style={{backgroundImage: 'url("./'+item.pictures.mini+'")'}}/>          
                    <div className="description">
                <h1>{item.title}</h1>
            </div>
                </div>
                <div className="description">
                <h1>{item.title}</h1>
                    {item.description.map((description, idx)=>(
                        <p key={idx}>{description}</p>
                    ))}
            </div>
            </div>
            {((selected === 'hjem' && index === 1) || (selected === 'sliping' && index === 4) || (selected === 'behandling' && index === 8)) && <GoogleComments />}
            {((selected === 'hjem' && index === 2) || (selected === 'sliping' && index === 3) || (selected === 'behandling' && index === 9)) && <ContactBaloons/>}
            {((selected === 'hjem' && index === 0) || (selected === 'sliping' && index === 5) || (selected === 'behandling' && (index === 7 || index === 10))) && <OrderBefaring/>}
        </>
        ))}
        {
            //(selected === 'about' || selected ==='hjem') && <GoogleComments />
        }
        {
         //<Tilbud/>
        }
        {(selected === 'about' || selected ==='hjem') && <AboutUs />}
        {selected === 'kontakt' && <ContactPanel />}
        {

        //selected ==='hjem' && <ContactBaloons/>
        }
        {

        //selected ==='hjem' && <OrderBefaring/>
        }
    </main>
    )}
}

export default MainContent;