
const comments = [
    {
        commentator: 'Jahn Andersen',
        comment: 'Veldig fornøyd med jobben, god service og proffe folk. Sliping ble utført av Mirek og han gjorde en super jobb.  Anbefales på det sterkeste.',
        link: 'https://maps.app.goo.gl/97Yq8iPbKXEqZQoT7',
        rate: 5,
        features: [],
        pictures: ['https://lh5.googleusercontent.com/p/AF1QipOGHBPd214PToc77m72JVgyLKIZ9L8QoLWKHIGa=w300-h225-p-k-no', 'https://lh5.googleusercontent.com/p/AF1QipNUiA96W8-AuHzxu0BCy9hTK9cf2lgBVIbeg3Qh=w300-h225-p-k-no'],
    },
    {
        commentator: 'Hjalmar Laudal Berge',
        comment: 'Profesjonell og høy kvalitet på utført arbeid, anbefales på det sterkeste!',
        link: 'https://g.co/kgs/5NSkN3',
        rate: 5,
        features: ['Kvalitet', 'Profesjonalisme', 'Punktlighet', 'Verdi'],
        pictures: [],
    },
    {
        commentator: 'Maria Holter',
        comment: 'Sliping og behandling av gamle furugulv i 50-tallsbolig. God kommunikasjon og helt strålende resultat.',
        link: 'https://g.co/kgs/qvKJoC',
        rate: 5,
        features: ['Kvalitet', 'Profesjonalisme', 'Punktlighet'],
        pictures: [],
    },
    {
        commentator: 'Mira Soria Røvang',
        comment: 'Jeg er kjempe fornøyd med resultatet på soveromsgulvet. Resultatet kunne ikke blitt bedre! Han som kom var punktlig, utrolig hyggelig og effektiv. Det er ingen tvil om at han kan sitt fagfelt. Jeg har ingen problemer med å bruke dette firmaet igjen neste gang jeg trenger hjelp med gulv! De var også best i pris av de firmaene jeg kontaktet. Tommel opp på alle punkter.',
        link: 'https://g.co/kgs/fKC3RE',
        rate: 5,
        features: ['Kvalitet', 'Profesjonalisme', 'Punktlighet', 'Responsivitet'],
        pictures: [],
    },
    
];

export {comments};