const getClientData = function (type) {
    const clientData = {
        clientName: "Jordan's Parkett AS",
        clientKey: "",
        uid: "JP777",
        clientCompanyTaxNumber: "923 398 341 MVA",
        clientCompanyName: "Jordan's Parkett AS",
        clientAddress: "Skogfaret 6C, 0382 Oslo",
        clientDomain: "jordansparkett.no",
        clientEmail: "mirek@jordansparkett.no",
        clientPhone: "+4740205263",
        clientFancyPhone: '40 20 52 63',
        instagram: "jordansparkett",
        facebook: "/",
    }
    return clientData[type];
}

const getYear = function () {
    const today = new Date();
    const year = today.getFullYear();
    return year;
}

export {getClientData, getYear};
