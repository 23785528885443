import React, { Component } from "react";
import './App.css';
import ContactForm from './ContactForm';
import ring from './ring.svg';
import envelope from './envelope.svg';

import { getClientData } from "./DataModelEngine";
import ContactBaloons from "./ContactBaloons";

class ContactPanel extends Component {
    constructor () {
        super();
        this.state = {
            messageId: false,
            messageForm: {
                email: false,
                mobile: false,
                name: false,
                message: false,
                accept: false,
            }
        }
        this.saveValues = this.saveValues.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }
    saveValues(object) {
        this.setState({
            messageForm: {
                ...this.state.messageForm,
                ...object,
            }
        })
    }

    sendForm() {
    const messageForm = this.state.messageForm;
    const address = {
        local: 'http://127.0.0.1:3003/api/jordansparkett/sendcontactform', 
        global: 'https://laskowskicreative.no/api/jordansparkett/sendcontactform'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(messageForm)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
        {
        messageId: json.messageId, 
        messageForm: {
            email: false,
            mobile: false,
            name: false,
            message: false,
            accept: false,
        }
            }
        ))  
    }
  render (){      
    return (
    <>
        <ContactBaloons/>
        <div id='kontakt' className='content'>
                <div className="content-background">
                </div>
                <div className='picture'>
                    <div className="picture-img" style={{backgroundImage: 'url("./contact.jpg")'}}/>          
                </div>
            <div className="description">
                <h1>Kontaktskjema</h1>
                <ContactForm sendForm={this.sendForm} saveValues={this.saveValues} {...this.state.messageForm}/>
            </div>
        </div>
    </>
    )}
}

export default ContactPanel;